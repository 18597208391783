<template>
  <div>
    <div class="bg-colorWhite" style="border-radius: 20px">
      <b-row class="d-flex align-items-start justify-content-end py-1">
        <b-col md="12" class="px-3 py-1">
          <h3 class="font-weight-bolder text-colorBlack">Math</h3>
          <p class="font-weight-bold text-colorGray mb-50">Code 4545</p>
          <h3 class="font-weight-bolder text-colorBlack">
            Introduction to Data Science & ML
          </h3>
        </b-col>
      </b-row>
      <b-card class="mt-2" style="border-radius: 20px">
        <b-table
          stacked="md"
          :fields="fieldsStudent"
          :items="Student"
          details-td-class="m-0 p-0"
          thead-class="tableHeadColor"
          small
          rounded
        >
          <template #head()="data">
            <div class="py-50">
              <span class="font-weight-bolder text-colorBlack">{{
                data.label
              }}</span>
            </div>
          </template>

          <template #cell(Name)="row">
            <div class="py-1">
              <span
                class="font-weight-bolder text-colorBlack m-0"
                style="font-size: 0.8rem"
              >
                {{ row.item.Name ? row.item.Name : "" }}
              </span>
            </div>
          </template>

          <template #cell(Created)="row">
            <span
              class="font-weight-bolder text-colorBlack m-0"
              style="font-size: 0.8rem"
            >
              {{ row.item.Created ? row.item.Created : "" }}
            </span>
          </template>

          <template #cell(Status)="row">
            <span
              class="font-weight-bolder text-colorBlack"
              style="font-size: 0.8rem"
            >
            </span>
            <b-badge
              pill
              size="sm"
              variant="success"
              v-if="row.item.Status === 'Submitted'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
            <b-badge
              pill
              size="sm"
              variant="danger"
              v-if="row.item.Status === 'Not Submitted'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
            <b-badge
              pill
              size="sm"
              variant="colorOrange"
              v-if="row.item.Status === 'Late Submission'"
            >
              {{ row.item.Status ? row.item.Status : "" }}
            </b-badge>
          </template>
          <template #cell(File)="row">
            <span
              class="font-weight-bolder text-colorBlack m-0"
              style="font-size: 0.8rem"
            >
              <img
                src="@/assets/images/icons/instructor/class/pdf.svg"
                img-fluid
                width="30px"
                height="30px"
              />
              {{ row.item.File ? row.item.File : "" }}
            </span>
          </template>
          <template #cell(Grade)="row">
            <span
              class="font-weight-bolder text-colorBlack m-0"
              style="font-size: 0.8rem"
            >
              {{ row.item.Grade ? row.item.Grade : "" }}
            </span>
          </template>
          <template #cell(manage)="">
            <b-button
              rounded
              size="sm"
              type="submit"
              variant="colorBlue"
              @click="openModalTeacherFeedback"
            >
              <span class="font-weight-bolder text-colorWhite">
                Add Feedback</span
              >
            </b-button>
          </template>
        </b-table>
      </b-card>
    </div>
    <TeacherFeedbackModal
      :modalVisible="modalVisibleTeacherFeedback"
      @close="closeModalTeacherFeedback"
    />
  </div>
</template>

<script>
import TeacherFeedbackModal from "@/components/instructor/assignment/TeacherFeedbackModal.vue";
export default {
  components: { TeacherFeedbackModal },
  data() {
    return {
      fieldsStudent: [
        { key: "Name", label: "Name" },
        { key: "Created", label: "Created" },
        { key: "Status", label: "Status" },
        { key: "File", label: "File" },
        { key: "Grade", label: "Grade" },
        { key: "manage", label: "" },
      ],
      Student: [
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Submitted",
          File: "Intro to Data.pdf",
          Grade: "A",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Not Submitted",
          File: "Intro to Data.pdf",
          Grade: "A",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Submitted",
          File: "Intro to Data.pdf",
          Grade: "A",
        },
        {
          Name: " Arslan Ahmed",
          Created: "24 Sep, 2020 02:00 pm",
          Status: "Late Submission",
          File: "Intro to Data.pdf",
          Grade: "A",
        },
      ],
      subjectCode: "",
      modalVisibleTeacherFeedback: false,
      value: 20,
      max: 100,
    };
  },
  methods: {
    openModalTeacherFeedback() {
      this.modalVisibleTeacherFeedback = true;
    },
    closeModalTeacherFeedback() {
      this.modalVisibleTeacherFeedback = false;
    },
  },
};
</script>

<style>
.tableHeadColor,
.table thead th,
thead,
th {
  background-color: #ebf0fc !important;
}
</style>
