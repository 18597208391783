<template>
  <b-modal
    id="view-Upload-modal"
    v-model="isModalVisible"
    :hide-footer="true"
    title="Add Feedback"
    title-class="my-title"
    centered
    @hide="emitClose"
  >
    <div class="text-center" style="border-radius: 20px">
      <b-form class="auth-login-form" @submit.prevent>
        <b-form-row class="py-1">
          <b-col class="text-left">
            <span class="font-weight-bolder text-colorBlack">Grade</span>
            <b-form-group class="mb-0">
              <v-select :options="['Math', 'English']" placeholder="Grade" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="py-1">
          <b-col class="text-left">
            <span class="font-weight-bolder text-colorBlack">Add Comment</span>
            <b-form-group class="mb-0">
              <b-form-textarea placeholder="" v-bind="comment" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="d-flex align-items-center">
          <b-col md="12">
            <div class="text-left">
              <h5 class="text-colorBlack font-weight-bolder mb-1">
                Add Attachment
              </h5>
            </div>
            <b-form-group label-for="file">
              <vue-dropzone
                ref="myVueDropzone"
                id="dropzone"
                :options="dropzoneOptions"
                :useCustomSlot="true"
                class="bg-colorLightBlue"
              >
                <div class="dropzone-custom-content">
                  <b-img
                    :src="
                      require('@/assets/images/icons/instructor/assignment/upload.svg')
                    "
                  />
                  <h3
                    class="dropzone-custom-title text-colorBlue mt-1 font-weight-bolder"
                  >
                    Upload Multiple Files
                  </h3>
                  <div class="subtitle">
                    <p>Click browse thorough your machine</p>
                  </div>
                </div>
              </vue-dropzone>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form>
    </div>
    <div class="text-right pb-2 px-50">
      <b-button
        style="margin-top: 260px"
        block
        type="submit"
        variant="colorBlue"
        class="mr-1"
        rounded
        :to="{ name: 'Assignment' }"
      >
        Done
      </b-button>
    </div>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { required } from "@validations";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vueDropzone: vue2Dropzone,
  },
  props: {
    modalVisible: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" },
      },
      comment: "",
      isModalVisible: false,
    };
  },
  watch: {
    modalVisible(value) {
      this.isModalVisible = value;
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
  },
};
</script>

<style>
.modal-dialog {
  display: flex;
  align-items: center;
  justify-content: center;
}
.my-title {
  color: black;
  font-weight: bold;
}
</style>
